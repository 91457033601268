import React, { Component, useState, useEffect } from 'react'
import emailjs from '@emailjs/browser';
import {useNavigate} from 'react-router-dom'

//Material UI
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import '../css/offerForm.css'

export function OfferForm(){
        let navigate = useNavigate();
        const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
        setOpen(true);
        };
    
        const handleClose = () => {
        setOpen(false);
        };
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [message, setMsg] = useState('');
        const [projectType, setPT] = useState('');
        const [budget, setBudget] = useState('');
        const [show, setShow] = useState(false);

        var templateParams = {
            name : name,
            message: message,
            email: email, 
            projectType: projectType,
            budget: budget
        }
    function handleSubmit(event) {
        event.preventDefault();
        setShow(prev => !prev)
        emailjs.send(process.env.REACT_APP_EMAIL_SERVICE_ID,process.env.REACT_APP_EMAIL_TEMPLATE2_ID,templateParams,process.env.REACT_APP_EMAIL_PUBLIC_KEY).then(function(response){
            handleClickOpen()
            setShow(false)
            setName('')
            setEmail('')
            setMsg('')
            setPT('')
            setBudget('')
        }, function(error){
            console.log('Failed...', error)
        });
    }
    const projects = [
        { label: 'Web App' },
        { label: 'Mobile App' },
        { label: 'System' }
      ];
    const budgets = [
        { label: '100' },
        { label: '200' },
        { label: '300' }
      ];
return(
    <div className="offerContainer">
         <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Hey!"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Your message has been sended, i will try to respond you the fast possible!
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <button className='dialogButton' onClick={() => {
                navigate("/")}}>Back to home</button>
            <button className='dialogButton' onClick={handleClose} autoFocus>
                Ok
            </button>
            </DialogActions>
        </Dialog>
        <h1 className='formTitle'>I'm excited to know about your idea.</h1>
        <form onSubmit={handleSubmit}>
            <div className='textUp'>
                <TextField placeholder="Email" style={{  width: '100%'}} sx={{p: 2}} value={email}
                    onInput={ e=>setEmail(e.target.value)} required>
                </TextField>
                <TextField placeholder="Name" style={{  width: '100%'}} sx={{p: 2}} value={name}
                    onInput={ e=>setName(e.target.value)} required>
                </TextField>
            </div>
            <div className='textUp'>
                <Autocomplete  
                    options={projects}
                    getOptionLabel={option => option.label  }
                    sx={{ width: '100%', p: 2 }} 
                    renderInput={(params) => <TextField {...params} label="Type of Project" variant='outlined' value={projectType} required/>}
                    onChange={ (e, obj) => {setPT(obj.label);}}
                />
                <Autocomplete  
                    options={budgets}
                    getOptionLabel={option => option.label  }
                    sx={{ width: '100%', p: 2 }} 
                    renderInput={(params) => <TextField {...params} label="Budget" variant='outlined' value={budget} required/>}
                    onChange={ (e, obj) => {setBudget(obj.label);}}
                />
            </div>
            <div className='textDown'>
                <TextareaAutosize label="Text" minRows={15} style={{ width: '100%',   }} placeholder="Tell me more" sx={{p: 2}} value={message}
                    onInput={ e=>setMsg(e.target.value)} required>
                </TextareaAutosize>
            </div>
            <Box sx={{ width: '80%' }} style={{alignSelf: 'center', textAlign: 'center', margin: 'auto', marginTop: '3rem', marginBottom: '3rem'}} >
                {show && <LinearProgress value={1} hidden={true} color='inherit' style={{background: 'black', color: 'crimson'}}/> }
            </Box>
            <button type='submit' className='submitButton'>SUBMIT</button>
        </form>
    </div>
)
}
export default OfferForm