import React, { Component } from 'react'
import '../css/about.css'

import form2 from '../assets/form2.png'
import form3 from '../assets/form3.png'
import me from '../assets/raul.jpg'
import me1 from '../assets/raul1.jpeg'
import me2 from '../assets/test.png'
import cv from '../assets/CV.pdf'
import { BiLogoJavascript, BiLogoReact, BiLogoNodejs, BiLogoGithub, BiLogoTypescript, BiLogoVuejs, BiLogoFirebase, BiLogoHtml5, BiLogoCss3, BiLogoBootstrap } from "react-icons/bi";
import { Fade, Slide, Bounce, Flip, Hinge, Reveal, Roll, Rotate, AttentionSeeker, Zoom, JackInTheBox  } from "react-awesome-reveal";
import { SiExpo } from "react-icons/si";

class About extends Component {
    constructor(){
        super()
        this.state = {
            showModal : false
        }
    }
    handleModal(){
        this.setState({showModal : !this.state.showModal})
    }
    render(){
        
    const skills = ['React','CSS','Javascript','PHP','Node JS','Vue JS'];
    const tools = ['Bootstrap','Github','Gitlab','Terminal'];
    return (
        <div className="about">
            <h2 className='titleText'>ABOUT ME</h2>
            <span className='minDiv'/>
            <div className='aboutContent'>
                <div className='divB'>
                    <Reveal triggerOnce>
                        <p className="abText">My name is Raúl, and its a pleasure.</p>
                        <p className="desText"> I started my career 3 years ago. I have worked with some companies as a frontend and backend developer with some projects. 
                            Coding websites, mobile apps, systems, backend and cloud services among others. I am very curious, dedicated and 100% self-taught.
                            Know my skills.
                        </p>
                        <a className='cvButton' href={cv} download>Download my CV</a>
                    </Reveal>
                </div>
                <div className='divA'>
                    <Fade direction='right' triggerOnce>
                        <img src={me2} className='meImg' alt=""/>
                    </Fade>
                </div>
            </div>
            <p style={{color: '#fff', fontWeight: 'bold', alignSelf: 'center', marginTop: '5%', fontSize: '1.5em', textAlign: 'center'}}>These are the technologies I have been working on.</p>
            <div className='skillContainer'>
                <div className='skillItem'>
                    <BiLogoJavascript size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>Javascript</p>
                </div>
                <div className='skillItem'>
                    <BiLogoReact size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>React</p>
                </div>
                <div className='skillItem'>
                    <BiLogoReact size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>React Native</p>
                </div>
                <div className='skillItem'>
                    <BiLogoNodejs size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>NodeJS</p>
                </div>
                <div className='skillItem'>
                    <BiLogoTypescript size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>TypeScript</p>
                </div>
                <div className='skillItem'>
                    <BiLogoGithub size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>Github</p>
                </div>
                <div className='skillItem'>
                    <BiLogoVuejs size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>VueJS</p>
                </div>
                <div className='skillItem'>
                    <BiLogoFirebase size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>FireBase</p>
                </div>
                <div className='skillItem'>
                    <BiLogoHtml5 size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>HTML</p>
                </div>
                <div className='skillItem'>
                    <BiLogoCss3 size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>CSS</p>
                </div>
                <div className='skillItem'>
                    <BiLogoBootstrap size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>Bootstrap</p>
                </div>
                <div className='skillItem'>
                    <SiExpo size={60} color='fff' style={{margin: 'auto'}}/>
                    <p className='skillText'>Expo CLI</p>
                </div>
            </div>
        </div>
    )
}
}

export default About
