import React from 'react'


import '../css/footer.css'

import logo2 from '../assets/logo2.png'
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FiMail } from "react-icons/fi";
import {useNavigate} from 'react-router-dom'


function Footer() {
    let navigate = useNavigate();

    return (
        <div className="footer">
            {/* <div className="secondContainer">
                <div className='divisions'>
                    <p>Start a project</p>
                </div>
                <div className='divisions'>
                   <a> Interested in working together? We should have a talk.</a> 
                </div>
                <div className='divisions'>
                    <button onClick={() => {
                        navigate("/offer")}}>
                            Let's do this 
                    </button>
                </div>
            </div> */}
            <img src={logo2} className="footerImg" alt=""/>
            {/* <p className='footerText'>improving day by day</p> */}
            <div className='iconContainer'>
                <a className='socialButton' href="https://www.instagram.com/raulfr99/" target="_blank" rel="noreferrer">
                    <span>
                    <FaInstagram size='1.5rem' color='white'/>
                    </span>
                </a>
                <a className='socialButton' href="https://www.linkedin.com/in/raulfr99/" target="_blank" rel="noreferrer">
                    <span>
                    <FaLinkedin size='1.5rem' color='white'/>
                    </span>
                </a>
                <a className='socialButton' href="mailto:info@mailgo.dev">
                    <span>
                    <FiMail size='1.5rem' color='white'/>
                    </span>
                </a>
            </div>
            <p className='legendText'>made with ♥ by me</p>

        </div>
    )
}

export default Footer
