import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import '../css/work.css';
import comp1 from '../assets/comp1.png';
import comp2 from '../assets/comp2.jpg';
import comp4 from '../assets/comp4.png';

const Work = () => {
  const workData = [
    {
      id: 1,
      title: 'Fullstack Developer',
      year: '2022 - Present',
      image: comp4,
      languages: ['JavaScript', 'React', 'Node.js', 'MongoDB', 'React Native', 'Python'],
    },
    {
      id: 2,
      title: 'Frontend Developer',
      year: '2021 - 2021',
      image: comp1,
      languages: ['Vue JS', 'Golang', 'React', 'MySQL'],
    },
    {
      id: 3,
      title: 'Frontend Developer',
      year: '2020 - 2020',
      image: comp2,
      languages: ['React Native', 'Python', 'MongoDB'],
    },
    // Add more work data as needed
  ];

  const WorkCard = ({ workItem }) => (
    <div className="workCardContainer">
      <Card className="myCard" style={{border: 'none'}}>
        <Card.Img variant="top" src={workItem.image} />
        <Card.Body>
          <Card.Title>
            {workItem.title} ({workItem.year})
          </Card.Title>
          <Card.Text style={{fontWeight: 'bold'}}>Languages: {workItem.languages.join(', ')}</Card.Text>
          {/* <Button variant="primary" onClick={() => console.log('Card clicked:', workItem.title)} style={{alignSelf: 'center'}}>
            View Details
          </Button> */}
        </Card.Body>
      </Card>
    </div>
  );

  return (
    <div className="myWork">
      <p className="rText">My recent work</p>

      <p className="wText">
        Im proud of collaborating with some companies, want to know more?
        <a className="emailMe" href="mailto:info@mailgo.dev">
          {' '}
          Send me an email
        </a>
      </p>

      <div className="wContainers">
        {workData.map((workItem) => (
          <WorkCard key={workItem.id} workItem={workItem} />
        ))}
      </div>

      <a
        className="moreButton"
        href="https://www.linkedin.com/in/raulfr99/"
        target="_blank"
        rel="noreferrer"
      >
        Check my LinkedIn
      </a>
    </div>
  );
};

export default Work;
