export const links = [
    {
        id: 1,
        text: 'HOME',
        url: 'home'
    },
    {
        id: 2,
        text: 'ABOUT',
        url: 'about'
    },
    {
        id: 3,
        text: 'MY WORK',
        url: 'mywork'
    },
    {
        id: 4,
        text: 'CONTACT',
        url: 'contact'
    },
]