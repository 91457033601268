import React from 'react'
import {useNavigate} from 'react-router-dom'
import '../css/contact.css'

function Contact() {
    let navigate = useNavigate();
    return (
        <div>
            <div className="cHome">
                <div className='container'>
                    <p className="conText">WANT TO WORK?</p>
                    <p className="contText"> Open to work offers and freelance projects.</p>
                    <button className='contactButton' onClick={() => {
                        navigate("/contact")
                    }}>CONTACT ME</button>
                </div>
            </div>
        </div>
    )
}

export default Contact
