import React, {useState} from 'react'
import {Animator, ScrollContainer, ScrollPage, Sticky, batch, MoveOut} from 'react-scroll-motion'
import '../css/page.css'
import Typical from 'react-typical'
import avatar from '../assets/avatar.png'
import form from '../assets/form1.png'
import profileImg from '../assets/profileImg.jpg'
import Lottie from "lottie-react";
import anTest from "../assets/devAnimation.json";
import StarfieldAnimation from 'react-starfield-animation'
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { Fade, Slide, Bounce, Flip, Hinge, Reveal, Roll, Rotate, AttentionSeeker, Zoom, JackInTheBox  } from "react-awesome-reveal";
import {Link} from 'react-scroll'

function Page() {
    const [varColor, setVarColor] = useState('yellow')
    return (
        // <div>
            <div className="home">
            <StarfieldAnimation
                style={{
                position: 'absolute',
                width: '100%',
                height: '100vh',
                zIndex: 0,

                }}
                numParticles={300}
            />
                <div className='leftHome'>
                    <Reveal style={{width: '100%', display: 'flex', alignSelf: 'center' }} triggerOnce={true} >
                        {/* <div style={{flexDirection: 'row', display: 'flex',  width: '100%', alignSelf: 'center'}}> */}
                            <p className='leftTitle'>Hello, I'm <span style={{color: '#B209AA',}}>Raúl</span></p>
                            {/* <p className='leftTitle' style={{fontWeight:'bold', color: '#B209AA', marginLeft: 15}}>Raul</p> */}
                        {/* </div> */}
                    </Reveal>
                    <p className='leftTitle'>Im a Fullstack Developer💻</p>
                    <p className='leftText'>I like to build applications, websites, software and everything possible.</p>
                    <div className='socialIcons'> 
                        <a href='https://github.com/raulfr99' style={{margin: '2%'}} target="_blank">
                            <AiFillGithub  size={60} color='#fff' className='socialIcon' />
                        </a>
                        <a href='https://www.linkedin.com/in/raulfr99/' style={{margin: '2%'}} target="_blank" >
                            <AiFillLinkedin size={60} color='#fff' className='socialIcon' />
                        </a>                            
                    </div>
                    <button className='workButton'><Link to='mywork'>VIEW MY WORK</Link></button>
                </div>
                <div className='rightHome'>
                    <div className='gradient' > </div>
                    <Lottie animationData={anTest} loop={true} className='animatedIcon' />
                </div>

                {/* <ScrollContainer>
                        <Animator animation={batch(Sticky(), Fade(), MoveOut(0, -200))}>
                        </Animator>
                </ScrollContainer> */}
                {/* <a className="homeTitle">Frontend Developer🦊</a> */}
                {/* <p className="prText">Hi my name is Raul, i like   */}
                {/* <Typical
                    steps={[
                        'Coding', 2000, 
                        'Cars', 2000,
                        'Football', 2000,
                        'Tractors', 2000,
                    ]}
                    loop={Infinity}
                    /> </p> */}
            </div>
        // </div>
    )
}

export default Page
