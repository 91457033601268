import React, { Component, useState, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import emailjs from '@emailjs/browser';
import {useNavigate} from 'react-router-dom'

//Material UI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import '../css/contactForm.css'


export function ContactForm(props){
        let navigate = useNavigate();
        const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
        setOpen(true);
        };
    
        const handleClose = () => {
        setOpen(false);
        };

        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [message, setMsg] = useState('');
        const [show, setShow] = useState(false);

        var templateParams = {
            name : name,
            message: message,
            email: email
        }
        function handleSubmit(event) {
            event.preventDefault();
            setShow(prev => !prev)
            emailjs.send(process.env.REACT_APP_EMAIL_SERVICE_ID,process.env.REACT_APP_EMAIL_TEMPLATE_ID,templateParams,process.env.REACT_APP_EMAIL_PUBLIC_KEY).then(function(response){
                handleClickOpen()
                setShow(false)
                setName('')
                setEmail('')
                setMsg('')
            }, function(error){
                console.log('Failed...', error)
            });
        }
        return (
            <div className='contactContainer'>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"Hey!"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your message has been sended, i will try to respond you the fast possible!
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <button className='dialogButton' onClick={() => {
                        navigate("/")}}>Back to home</button>
                    <button className='dialogButton' onClick={handleClose} autoFocus>
                        Ok
                    </button>
                    </DialogActions>
                </Dialog>
                <h1 className='formTitle'>Thanks for getting in contact with me, i would try to give you a fast response. </h1>
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className='textUp'>
                            <TextField   placeholder='Your name' required  value={name}
                            onInput={ e=>setName(e.target.value)} autoComplete="none" style={{  width: '100%'}} sx={{p: 2}}>
                            </TextField>
                            <TextField   placeholder='Your email' type="email" required value={email}
                            onInput={ e=>setEmail(e.target.value)} autoComplete="none" style={{  width: '100%'}} sx={{p: 2}}></TextField>
                        </div>
                        <div className='textDown'>
                            <TextareaAutosize label="Text" minRows={15} style={{ width: '100%', borderColor: 'lightgray', borderRadius: '5px'   }} placeholder="Tell me more" value={message}
                            onInput={ e=>setMsg(e.target.value)} sx={{p: 2}}> </TextareaAutosize>
                        </div>
                        <Box sx={{ width: '60%' }} style={{alignSelf: 'center', textAlign: 'center', margin: 'auto', marginBottom: '20px'}} >
                          {show && <LinearProgress value={1} hidden={true} color='inherit' style={{background: 'black', color: '#B209AA', }}/> }
                         </Box>
                        <button type='submit' className='submitButton'>SUBMIT</button>
                    </form>
                    
                </div>
            </div>
        )
    
}
export default ContactForm